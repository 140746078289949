.wrapperTwitterFeed {
  width: 100%;
  height: 3rem;
  background: #f4f4f4;
  padding-left: 100%;
  box-sizing: content-box;
}

.twitterFeed {
  display: inline-block;
  height: 3rem;
  line-height: 3rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 200s;
  animation-duration: 200s;

  /* padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  background-color: #e74c3c;
  color: white;
  font-size: 18px; */
}

.twitterFeedItem {
  display: inline-block;

  padding: 0 2rem;
  font-size: 1rem;
  color: rgb(99, 91, 91);
  /* padding: 5px 7px;
  border-right: 1px solid #fafafa;
  white-space: nowrap; */
  /* transform: translateX(100%);
  animation: move 30s linear infinite; */
}

/* @keyframes move {
  to {
    transform: translateX(-100%);
  }
} */
* {
  box-sizing: border-box;
}
/* $duration: 30s; */

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: rgba(#000, 0.9);
  padding-left: 100%;
  box-sizing: content-box;
}
.ticker {
  display: inline-block;
  height: 4rem;
  line-height: 4rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}

.item {
  display: inline-block;

  padding: 0 2rem;
  font-size: 2rem;
  color: white;
}

body {
  padding-bottom: 5rem;
}
h1,
h2,
p {
  padding: 0 5%;
}
