.wrapperFooter{
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer {
  display: flex;
  padding: 40px 10px 15px 0px;
}
.contact {
  padding: 0px 5px;
  margin: 0px 10px;
  cursor: pointer;
}
.icons:hover {
  color: rgb(93 101 137);;
}
.icons {
  padding: 0px !important;
  font-size: 20px;
  color: #706161;
}
