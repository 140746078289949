.wrapperHomeContainer {
  background-image: radial-gradient(#444444, #232322, #131312);
  background-position: center;
  height: 100vh;
}

.logoContainer {
  position: absolute;
  top: 41%;
  right: 50%;
}
.logo {
  width: 30vh;
  display: flex;
  justify-content: center;
  background-image: url('../../assets/JACT_white.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 16vh;
  position: relative;
  right: -50%;
}
.wrapperFooter {
  position: absolute;
  bottom: 2%;
  width: 100%;
}
.footer {
  display: flex;
  padding: 40px 40px 15px 40px;
  justify-content: flex-end;
}
.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 25px;
  cursor: pointer;
}
.icons:hover {
  color: #5a4a0d;
}
.icons {
  padding: 0px !important;
  font-size: 20px;
  color: #706161;
}
