.wrapperVisionAndMission {
	background-color: #f4f4f4;
	width: 100%;
	/* display: flex; */
	padding-top: 30px;
	line-height: 40px;
}

.visionAndMissionImg {
	width: 100%;
	display: flex;
	justify-content: center;
}
.img {
	box-shadow: 1px 1px 16px #767676;
	-moz-box-shadow: 1px 1px 16px #767676;
	-webkit-box-shadow: 1px 1px 16px #767676;
	-khtml-box-shadow: 1px 1px 16px #767676;
	max-width: 100%;
	min-width: 300px;
	height: auto;
	width: 80%;
}
.visionAndMissionText {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 19px;
	color: #565555;
	padding: 20px;
}
.titleVm {
	font-size: 19px;
	font-weight: 600;
	margin-bottom: 10px;
	letter-spacing: 0.1em;
}
.text {
	width: 80%;
}

/* mobile view  */

@media (max-width: 990px) {
	.wrapperVisionAndMission {
		display: flex;
		flex-direction: column;
	}
	.visionAndMissionImg {
		width: 100%;
	}
	.img {
		max-width: 100%;
		min-width: 300px;
		height: auto;
		width: 100%;
	}
	.visionAndMissionText {
		width: 100%;
		text-transform: none;
		font-size: 15px;
		flex-direction: column;
	}
	.titleVm {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 5px;
	}
	.text {
		width: 100%;
	}
}
