.wrapperQandA {
	width: 100%;
	background-color: #f4f4f4;
	display: flex;
	/* flex-direction: column; */
	font-size: 19px;
	padding-top: 50px;
	line-height: 40px;
	justify-content: center;
}
.wrapperInfo {
	width: 80%;
}

.qAndAText {
	align-items: center;
	display: flex;
	padding: 20px;
	width: 100%;
	color: #565555;
	border-bottom: 1px solid #cbcbcb;
}
.paragraph {
	font-weight: 600;
}
.qanda {
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 10px;
	letter-spacing: 0.1em;
	color: #565555;
	padding-left: 50px;
}

.questionIcon {
	margin: 30px;
}
.wrapperQuestion {
	display: flex;
	flex-direction: column;
}

/* mobile view  */

@media (max-width: 990px) {
	.wrapperQandA {
		flex-direction: column;
		color: #565555;
		text-align: center;
	}
	.wrapperInfo {
		width: 100%;
	}
	.qanda {
		font-size: 19px;
		font-weight: 600;
		padding: 20px 0px 0px;
		text-align: center;
	}
	.qAndAText {
		width: 100%;
		flex-direction: column;
		font-size: 15px;
	}
	.paragraph {
		font-weight: 600;
		font-size: 16px;
	}
}
